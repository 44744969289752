import { RESPONSE } from '../../../../express.tokens';
import { Inject, Injectable, Optional } from '@angular/core';
import { Response } from 'express';

/**
 * Note that this service is currently not useful due to the issue discussed
 * here: https://github.com/angular/universal/issues/827#issuecomment-1600444778
 */
@Injectable({ providedIn: 'root' })
export class ServerResponseService {
    private response: Response;

    constructor(@Optional() @Inject(RESPONSE) response: any) {
        this.response = response;
    }

    getHeader(key: string): string {
        return this.response.getHeader(key) as string;
    }

    setHeader(key: string, value: string): this {
        if (this.response) this.response.header(key, value);
        return this;
    }

    appendHeader(key: string, value: string, delimiter = ','): this {
        if (this.response) {
            const current = this.getHeader(key);
            if (!current) return this.setHeader(key, value);

            const newValue = [...current.split(delimiter), value]
                .filter((el, i, a) => i === a.indexOf(el))
                .join(delimiter);

            this.response.header(key, newValue);
        }
        return this;
    }

    setHeaders(dictionary: { [key: string]: string }): this {
        if (this.response) Object.keys(dictionary).forEach((key) => this.setHeader(key, dictionary[key]));
        return this;
    }

    setStatus(code: number, message?: string): this {
        if (this.response) {
            this.response.statusCode = code;
            if (message) this.response.statusMessage = message;
        }
        return this;
    }

    redirect(url: string): this {
        if (this.response) {
            this.response.statusCode = 301;
            // this.response.header('location', url);
        }
        return this;
    }
}
